

.background { position: absolute; width: 100vw; height: 100vh; top: 0; display: flex; justify-content: flex-end; align-items: center; }
.background:after { content: ''; height: 100%; width: 25%; position: absolute; right: 0; top: 0; bottom: 0; }
.background .background-container{ width: 50%; height: 100%; position: relative; display: flex; justify-content: flex-start; align-items: center; }
.background .background-container .triangle { width: 1500px; height: 1500px; transform: rotate(45deg); border-radius: 50px; flex-shrink: 0; position: absolute; display: block; transform-style: preserve-3d; transform-origin: center center; }
.background .background-container .triangle::before { content: ''; height: 100%; width: 100%; position: absolute; left: -30px; border-radius: 50px; top: -30px; bottom: 0; display: block; z-index: -1; transform: translateZ(-1em); }


.contenido{ display: flex; width: 100%; align-items: center; justify-content: space-between; }
.contenido > [class^="col"]{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center; justify-content: center;
}
.contenido .logo{}

.contenido .formulario{z-index: 1;}
.contenido .formulario .login{padding: 50px;}

.contenido .formulario ::placeholder { color: #fff !important; opacity: 1 !important; }
.contenido .formulario :-ms-input-placeholder { color: #fff !important; }
.contenido .formulario ::-ms-input-placeholder { color: #fff !important; }
.contenido .formulario input 
.contenido .formulario input.invalid{ border-color: red !important; }
.contenido .formulario .login button{ width: 100%; margin: 0% !important; background-color: transparent !important; border: 1px solid white;}
.contenido .formulario .login button:hover{ width: 100%; margin: 0% !important; background-color: transparent !important; border: 1px solid gray; color: gray !important; background-image: none !important;}

.contenido .formulario .login .extra { margin-top: 20px; display: flex; justify-content: space-between; min-width: 100%; padding: 10;}
.contenido .formulario .login .extra a { color: #fff !important; text-align: center; cursor: pointer; z-index: inherit;}
.contenido .formulario .login .extra a:hover{ color: gray !important; text-align: center; cursor: pointer; z-index: inherit;}
.contenido .formulario .login .acciones { display: flex; align-items: center; justify-content: space-around; margin-top: 16px; color: #fff; }
.contenido .formulario .login .acciones a{ width: 40% !important; color: #fff !important; text-align: center; cursor: pointer; z-index: inherit;}

@media (min-width: 1600px) {
    /* body { border: 5px solid brown;} */
    .background .background-container .triangle { left: 200px;}
}

/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1599px) {
    /* body { border: 5px solid yellow;} */
    .background .background-container .triangle { left: 100px;}
    
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    /* body { border: 5px solid green;} */
    .background .background-container .triangle { left: 150px;}
    .background:after { background-color: transparent; }
}

@media (max-width: 991px){
    /* Landscape phones and portrait tablets */
    /* body { border: 5px solid purple;} */
    .contenido .col-1{ width: 30%; }
    .contenido .col-2{ width: 50%; }
    .background .background-container .triangle { left: 150fpx;}
    .background:after { background-color: transparent; }
}

@media (max-width: 750px){

}

@media (max-width: 600px) {
    /* body { border: 5px solid orange;} */
    .contenido{ flex-direction: column}
    .contenido .col-1{ width: 100%; height: 30%; }
    .contenido .col-2{ width: 100%; height: 70%; }
    .contenido .formulario .login { width: 350px; margin-top: 50px; }
    .background { flex-direction: column; align-items: flex-end}
    .background .background-container{ width: 100%; height: 50%;}
    .background .background-container .triangle{ width: 600px; height: 600px; position: fixed; left: 50%; top: 50%; margin-left: -300px; }
    .background .background-container .triangle::before { left: 30px; top: -30px; }
}

/* Landscape phones and smaller */
@media (max-width: 500px) {
    /* body { border: 5px solid red;}  */
}

@media (max-width: 480px) {
}