svg {
    transform-origin: bottom;
    /* animation: scaleStart 0.5s ease-out forwards; */
  }
  
  foreignObject.logoBack {
    /* transform: rotate(90deg); */
    transform-origin: 50px 50px;
    /* animation: colorRotate 7.5s 0.5s 1 linear; */
  }
  
  div.logoGradient {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.8;
    background: conic-gradient(from 270deg, blue 1%,blue, green, green, rgb(224, 188, 28), red, red 50%);
  }
  
  g.logoBlend {
    mix-blend-mode: lighten;
  }
  
  g.logoBlend > rect {
    fill: white;
  }
  
  g.logoBlend > path {
    stroke: black;
    stroke-linecap: butt;
    box-shadow: 0 0 5px gray;
    fill: none;
    /* animation:
      pathStroke 3.75s 0.5s forwards ease-in,
      pathRotate 3s 0.5s forwards ease-in; */
    transform: rotate(90deg);
    transform-origin: center;
    /* transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14); */
    transition: all 0.6s ease-in;
    transition-delay: 0.2s;
  }
  .animateMarker {
    transition: all 0.5s ease-in;
    transition-delay: 0s;
  }
  @keyframes scaleStart {
    0% { transform: scale(0.5); }
    60% {
      transform: scale(1.25);
      animation-timing-function: ease-in;
    }
    100% { transform: scale(1); }
  }
  
  /* @keyframes colorRotate {
    from { transform: rotate(270deg); }
    to { transform: rotate(-90deg); }
  } */
  
  /* @keyframes pathStroke {
    0% { stroke-dashoffset: 288.5; }
    40%, 70% { stroke-dashoffset: 577; }
    100% { stroke-dashoffset: 865.5; }
  } */
  
  @keyframes pathRotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-90deg); }
  }

