

  .grid-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch; /* Los componentes se estiran para tener la misma altura */
  }
  
  .left-component {
    flex: 1; /* El componente de la derecha ocupa todo el ancho disponible */
    position: relative;
    overflow: hidden;
  }
  
  .right-component {
    width: 400px; /* El ancho se ajusta automáticamente al contenido */
    height: 400px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;

  }
  .center-component {
    width: 100%; /* El ancho se ajusta automáticamente al contenido */
    max-height: 100vw;
    margin-bottom: 8px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;
    min-height: calc(100vw - 16px);
    border-radius: 10px;
  }
  