.empty-header,
.empty-footer {
    opacity: 0;
    width: 100%;
}
.header{
    position: fixed;
    top: 0;
    width: 100%;
    background-color: aquamarine;
}
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: aquamarine;
}
.footer {
    bottom: 0
}