.grid-container-battery {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;
    height: 100%;
    width: 100%;
    /* Los componentes se estiran para tener la misma altura */
}

.left-component-battery {
    flex: 1;
    /* El componente de la derecha ocupa todo el ancho disponible */
    position: relative;
    overflow: hidden;
    border: 2px solid white;
}

.right-component-battery {
    width: 9%;
    /* El ancho se ajusta automáticamente al contenido */
    height: 100%;
    /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;

}

.child-battery {
    animation: fade-in 1s forwards;
    width: 0px;
  }
  
  @keyframes fade-in {
    0% {
      width: 0;
    }
    100% {
        width: 33.33%;
    }
  }
  
  .child-battery:nth-child(1) {
    animation-delay: 0s;
  }
  
  .child-battery:nth-child(2) {
    animation-delay: 1s;
  }
  
  .child-battery:nth-child(3) {
    animation-delay: 2s;
  }
  
  .child-battery:nth-child(4) {
    animation-delay: 3s;
  }