

  .grid-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch; /* Los componentes se estiran para tener la misma altura */
  }
  
  .left-component {
    flex: 1; /* El componente de la derecha ocupa todo el ancho disponible */
    position: relative;
    overflow: hidden;
  }
  
  .right-component {
    width: 400px; /* El ancho se ajusta automáticamente al contenido */
    height: 400px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;

  }
  .center-component {
    width: 100%; /* El ancho se ajusta automáticamente al contenido */
    margin-bottom: 8px; /* La altura se ajusta automáticamente a la del componente de la derecha */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  .text {
    width: 100%; /* El texto ocupará todo el ancho disponible */
    height: 100%; /* El texto ocupará todo el alto disponible */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.2rem;
    overflow: hidden;
    font-size: 0.6rem; /* Tamaño de la letra inicial */
    white-space: nowrap; /* El texto no se dividirá en varias líneas */
  }
  .container-text {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 2px;
  }